<template>
	<div class="celebrate-content">
		<div class="celebrate-title">中奖名单<el-button type="primary" @click="exportExcel">导出名单</el-button></div>
		<div class="celebrate-main">
			<div class="celebrate-item">
				<el-button type="primary">周年庆-面值一千元代金券</el-button>
				<h3>获奖名单</h3>
				<ul>
					<li v-for="(item,index) in prize1">
						<p>{{ item.real_name }}</p>
						{{ item.phone }}
						<el-button type="text" style="margin-left: 10px;" @click="del(item)">删除</el-button>
					</li>
				</ul>
			</div>
			<div class="celebrate-item">
				<el-button type="primary">周年庆-面值一千元代金券</el-button>
				<h3>获奖名单</h3>
				<ul>
					<li v-for="(item,index) in prize2"><p>{{ item.real_name }}</p>{{ item.phone }}
						<el-button type="text" style="margin-left: 10px;" @click="del(item)">删除</el-button>
					</li>
				</ul>
			</div>
			<div class="celebrate-item">
				<el-button type="primary">周年庆-会员年卡一年</el-button>
				<h3>获奖名单</h3>
				<ul>
					<li v-for="(item,index) in prize3"><p>{{ item.real_name }}</p>{{ item.phone }}
						<el-button type="text" style="margin-left: 10px;" @click="del(item)">删除</el-button>
					</li>
				</ul>
			</div>
			<!-- <div class="celebrate-item">
				<el-button type="primary">周年庆-内容U盘</el-button>
				<h3>获奖名单</h3>
				<ul style="justify-content: left">
					<li v-for="(item,index) in prize4"><p>{{ item.real_name }}</p>{{ item.phone }}
						<el-button type="text" style="margin-left: 10px;" @click="del(item)">删除</el-button>
					</li>
				</ul>
			</div>
			<div class="celebrate-item">
				<el-button type="primary">调研-原价3688/年会员权益</el-button>
				<h3>获奖名单</h3>
				<ul style="justify-content: left">
					<li v-for="(item,index) in prize5"><p>{{ item.real_name }}</p>{{ item.phone }}
						<el-button type="text" style="margin-left: 10px;" @click="del(item)">删除</el-button>
					</li>
				</ul>
			</div>
			<div class="celebrate-item">
				<el-button type="primary">调研-《尿石症问与答》</el-button>
				<h3>获奖名单</h3>
				<ul style="justify-content: left">
					<li v-for="(item,index) in prize6"><p>{{ item.real_name }}</p>{{ item.phone }}
						<el-button type="text" style="margin-left: 10px;" @click="del(item)">删除</el-button>
					</li>
				</ul>
			</div>
			<div class="celebrate-item">
				<el-button type="primary">调研-《泌尿腹腔镜与机器人手术专业书籍》</el-button>
				<h3>获奖名单</h3>
				<ul style="justify-content: left">
					<li v-for="(item,index) in prize7"><p>{{ item.real_name }}</p>{{ item.phone }}
						<el-button type="text" style="margin-left: 10px;" @click="del(item)">删除</el-button>
					</li>
				</ul>
			</div> -->
		</div>
	</div>
</template>

<script>

	let tableColumns = [
		{
			title: 'uid',
			dataIndex: 'uid',
			key: 'uid',
			fixed: 'left',
		},
		{
			title: '类型',
			dataIndex: 'type',
			key: 'type',
			fixed: 'left',
		},
		{
			title: '类型名称',
			dataIndex: 'type_txt',
			key: 'type_txt',
			fixed: 'left',
		},
		{
			title: '姓名',
			dataIndex: 'real_name',
			key: 'real_name',
			fixed: 'left',
		},
		{
			title: '手机号',
			dataIndex: 'phone',
			key: 'phone',
			fixed: 'left',
		},
		{
			title: '医院名',
			dataIndex: 'hospital',
			key: 'hospital',
			fixed: 'left',
		},
		{
			title: '职称等级',
			dataIndex: 'level',
			key: 'level',
			fixed: 'left',
		},
		{
			title: '省',
			dataIndex: 'province',
			key: 'province'
		},
		{
			title: '市',
			dataIndex: 'city',
			key: 'city'
		},
		{
			title: '区',
			dataIndex: 'county',
			key: 'county'
		}
	];

	import { export2Excel } from '@/utils/exportExcel'
	export default {
		data() {
			return {
				prize1: [],
				prize2: [],
				prize3: [],
				prize4: [],
				prize5: [],
				prize6: [],
				prize7: [],
				tableColumns,
				tableData: []
			}
		},
		mounted() {
			this.getList();
		},
		methods: {
			getList() {
				this.$axios.post(this.apiUrl.luckDrawYear_All2, {}).then(res => {
					// console.log(res)
					this.tableData = res.data;
					this.prize1 = [];
					this.prize2 = [];
					this.prize3 = [];
					this.prize4 = [];
					this.prize5 = [];
					this.prize6 = [];
					this.prize7 = [];
					res.data.map(item => {
						if (item.type == 1) {
							this.prize1.push(item)
						} else if (item.type == 2) {
							this.prize2.push(item)
						} else if (item.type == 3) {
							this.prize3.push(item)
						} else if (item.type == 4) {
							this.prize4.push(item)
						} else if (item.type == 5) {
							this.prize5.push(item)
						} else if (item.type == 6) {
							this.prize6.push(item)
						} else if (item.type == 7) {
							this.prize7.push(item)
						}
					})
				})
			},
			del(item) {
				console.dir(item.uid)
				this.$confirm(`确定删除此中奖人（${item.real_name}-${item.phone}）, 是否继续?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios.post(this.apiUrl.delLuckDraw, {
						uu_uid: item.uid,
						phone: item.phone,
						type: item.type
					}).then(res => {
						if(res.code == 11){
							this.getList()
							this.$message.success("删除成功");
						}else{
							this.$message.warning(res.msg)
						}
					})
				})
			},
			exportExcel() {
				this.tableData.map(item => {
					if(item.type == 1) {
						item.type_txt = '819 -面值一千元代金券'
					}else if(item.type == 2) {
						item.type_txt = '819 -面值一千元代金券'
					}else if(item.type == 3) {
						item.type_txt = '819 -会员年卡一年'
					}
					// else if(item.type == 4) {
					// 	item.type_txt = '819 -内容U盘'
					// }else if(item.type == 5) {
					// 	item.type_txt = '调研 -原价3688/年会员权益'
					// }else if(item.type == 6) {
					// 	item.type_txt = '调研 -《尿石症问与答》'
					// }else if(item.type == 7) {
					// 	item.type_txt = '调研 -《泌尿腹腔镜与机器人手术专业书籍》'
					// }
				})
				export2Excel(this.tableColumns,this.tableData,`中奖名单`)
			}
		}
	}
</script>

<style scoped lang="scss">
	.celebrate-content {
		color: #fff;
		background: url("../../assets/img/celebrate.jpg") no-repeat;
		background-size: cover;
		background-position: bottom;

		.celebrate-title {
			font-size: 40px;
			color: #fff;
			text-align: center;
			padding-top: 50px;
		}

		.celebrate-main {
			display: flex;
			padding: 50px;
			flex-wrap: wrap;
		}

		.celebrate-item {
			width: 50%;
			text-align: center;
			margin-bottom: 110px;
			box-sizing: border-box;
			border-right:2px solid #fff;
			padding: 0 20px;
			&:nth-child(2n) {
				border-left:2px solid #fff;
				border-right: 0;
			}

			h3 {
				font-size: 26px;
				margin-top: 20px;
			}

			ul {
				margin-top: 20px;
				display: flex;
				flex-wrap: wrap;
				text-align: center;
				align-items: center;
				align-content: center;
				justify-content: center;

				li {
					display: inline-block;
					font-size: 18px;
					color: #f5f5f5;
					width: 20%;
				}
			}
		}
		.celebrate-item:nth-child(3){
			width: 100%;
			border-right: 0px solid #fff;
		}
	}

	.prizeNum {
		font-size: 30px;
	}
</style>
